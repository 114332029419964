<template>
  <div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { getAction } from '@/utils/manage';
import { getCurrentInstance } from "vue";
import { useRouter, useRoute } from 'vue-router'
import { ElLoading } from 'element-plus'

const isWeiXin = ref();
const { proxy } = getCurrentInstance()
const router = useRouter()
const route = useRoute()

function getTokenAndUserId(authCode) {
  return getAction(`/fy/ali/openid?auth_code=${authCode}`)
}

onMounted(() => {
  const loading = ElLoading.service({
    lock: true,
    text: '数据加载中，请稍等',
    background: 'rgba(0, 0, 0, 0.7)',
  })
  const ua = window.navigator.userAgent.toLowerCase();
  isWeiXin.value = (ua.match(/MicroMessenger/i) == 'micromessenger');
  // isWeiXin.value = true
  if (isWeiXin.value) {
    getAction('fy/wx/openid', {parkId: route.query.parkId}).then(res => {
      loading.close()
      if (res.code === 200) {
        location.href = res.data.url
      }
    })
  } else {
    // getTokenAndUserId('7c919f625a7546cdbc3739b3babfJX01')
    const aliPay = proxy.$aliPay
    aliPay.getAuthCode({
      appId: '2021003187623296',// 2021003199627951 // 2021003187623296
      scopes: 'auth_user',
      success: (authRes) => {
        // alert(JSON.stringify(authRes))
        getTokenAndUserId(authRes.authCode).then(res => {
          // alert(JSON.stringify(res))
          loading.close()
          if (res.code === 200) {
            // alert(JSON.stringify(res))
            router.push({ path: '/cost', query: { userId: res.data.user_id, parkId: route.query.parkId } })
          }
        })
      },
      fail: () => {
        loading.close()
      }
    });
  }
})
</script>

<style lang="scss" scoped></style>
