import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CostView from '../views/CostView.vue'
import SuccessView from '../views/SuccessView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/cost',
    name: 'cost',
    component: CostView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/payment',
    name: 'payment',
    component: import('@/views/PaymentInfoView.vue')
  },
  {
    path: '/NoLicense',
    name: 'NoLicense',
    component: import('@/views/NoLicense.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
